import React from "react"
import blob from "./../../images/Vector.svg"
import styled from "styled-components"

const CreditsH = styled.h4`
  color: #708d81;
  font-family: Lerty;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 0;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`
const CreditsSpan = styled.span`
  color: #708d81;
  font-family: Leiko;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;

  a {
    color: #708d81;

    &:hover {
      color: #ef959d;
    }
  }
`

const FooterContainer = styled.footer`
  padding: 0px 40px;
  margin: 0 auto;
  max-width: 1440;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-15%, -50%);

  @media (max-width: 768px) {
    transform: translate(-40%, -50%);
  }
`
const Flex = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`

const Image = styled.img`
  max-width: 100%;
  @media (max-width: 768px) {
    max-width: 70%;
  }
`

const Footer = () => {
  return (
    <FooterContainer>
      <div style={{ width: "max-content", position: "relative" }}>
        <Image src={blob} alt="blob" />
        <Container>
          <CreditsH>Credits</CreditsH>
          <Flex>
            <CreditsSpan>
              Title typeface : Lerty{" "}
              <a
                target="_blank"
                href="https://www.instagram.com/fartifacts/"
                rel="noreferrer"
              >
                @fartifacts
              </a>
            </CreditsSpan>
            <CreditsSpan>
              Body typeface : Leiko{" "}
              <a
                target="_blank"
                href="https://www.instagram.com/vrs.a.design/"
                rel="noreferrer"
              >
                @vrs.a.design
              </a>
            </CreditsSpan>
            <CreditsSpan>
              Developer :{" "}
              <a
                target="_blank"
                href="https://www.linkedin.com/in/emil-fjellstr%C3%B6m-ab2a09134/"
                rel="noreferrer"
              >
                Emil Fjellström
              </a>
            </CreditsSpan>
          </Flex>
        </Container>
      </div>
    </FooterContainer>
  )
}

export default Footer
