import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../Header/header"
import "./Layout.css"
import Footer from "../Footer/Footer"
import styled from "styled-components"

const Container = styled.div`
  flex: 1;
  margin: 0 auto;
  max-width: 1440;
  padding: 0 120px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`

const Flex = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Flex>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Container>
        <main style={{ width: `100%` }}>{children}</main>
      </Container>
      <Footer />
    </Flex>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
