import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./header.css"
import styled from "styled-components"

const Container = styled.div`
  margin: 0 auto;
  max-width: 1440;
  padding: 50px 40px 0px 40px;

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#003B28`,
      marginBottom: `1.45rem`,
    }}
  >
    <Container>
      <h1 className="Header">
        <Link
          to="/"
          style={{
            color: "#faf3c8",
            textDecoration: `none`,
            fontSize: `calc(5vw + 1rem)`,
          }}
        >
          Ellie Toner
        </Link>
      </h1>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
